import React from 'react';
import styled from 'styled-components';
import './UserProfile.css';

const ImagePreview = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
`;

const UserProfile = ({ user }) => {
  if(!user){
    return <p>Loading...</p>;
  }
  return (
    <div className="user-profile">
      <ImagePreview src={user.picture || 'default-picture.jpg'} alt="User" ></ImagePreview>
      <h2>{user.firstName} {user.lastName}</h2>
      <p>{user.email}</p>
      <p>{user.role}</p>
    </div>
  );
};

export default UserProfile;