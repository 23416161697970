import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './components/auth/LoginPage';
import UserAdminPage from './components/admin/UserAdminPage';
import AccountActivated from './components/Activations/AccountActivated';
import AuthGuard from './components/Security/AuthGuard';

const ProtectedUserAdminPage = AuthGuard(UserAdminPage);

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin/users" element={< ProtectedUserAdminPage />} />
      <Route path="/accountactivated" element={<AccountActivated />} />
      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  </Router>
);

export default AppRouter;