import axios from 'axios';

export const login = (email, password) => async (dispatch) => {
  try {
    const response = await axios.post('https://eckznaqxba.execute-api.eu-west-2.amazonaws.com/dev/api/auth/token', { email, password });
    //console.log(response.data);
    dispatch({ type: 'LOGIN_SUCCESS', payload: response.data, token: response.data.jwtToken });
    return response.data; // Return userData
  } catch (error) {
    console.error(error);
  }
};