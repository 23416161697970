import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthGuard = (Component) => {
  const WrappedComponent = (props) => {
    const token = useSelector(state => state.auth.token); // Access the token from the auth state
    const user = useSelector(state => state.auth.user);
    console.log('Authguard ' + JSON.stringify(useSelector(state => state.auth.user)));
    console.log('User: ' + JSON.stringify(user));
    if (user !== null && !user.jwtToken) {
      return <Navigate to="/login" />;
    }

    return <Component {...props} />;
  };

  return WrappedComponent;
};

export default AuthGuard;