import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import styled from 'styled-components';
import UserProfile from './UserProfile';
import ManageUsersPage from './ManageUsersPage';
import { useSelector } from 'react-redux';

const Button = styled.button`
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
`;

const ImagePreview = styled.img`
  width: 70px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%', // Adjust the width
    height: '60%', // Adjust the height
  },
};

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; // This will align the content to the left
  width: 100%; // Add this line
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 0 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

Modal.setAppElement('#root');

const UserAdminPage = () => {
    const user = useSelector(state => state.auth.user);
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isManageUsersModalOpen, setIsManageUsersModalOpen] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [titles, setTitles] = useState([]);
  const [newUser, setNewUser] = useState({
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    userType: '',
    userImage: '',
  });

  console.log(user);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://eckznaqxba.execute-api.eu-west-2.amazonaws.com/dev/api/backoffice/stafflist'); // Replace with your API endpoint
        setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserRoles = async () => {
      try {
        const response = await axios.get('https://eckznaqxba.execute-api.eu-west-2.amazonaws.com/dev/api/backoffice/userroles'); // Replace with your API endpoint
        setUserRoles(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTitles = async () => {
      try {
        const response = await axios.get('https://eckznaqxba.execute-api.eu-west-2.amazonaws.com/dev/api/backoffice/titles'); // Replace with your API endpoint
        setTitles(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
    fetchUserRoles();
    fetchTitles();
  }, []);

  const handleInputChange = (event) => {
    setNewUser({ ...newUser, [event.target.name]: event.target.value });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
      setNewUser({...newUser, userImage: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
      setNewUser({...newUser, userImage: ''});
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(newUser) {
        newUser.userType = parseInt(newUser.userType, 10);
        newUser.password = btoa(newUser.password);
        axios.post('https://eckznaqxba.execute-api.eu-west-2.amazonaws.com/dev/api/auth/register', newUser, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            console.log('Success:', response.data);
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }
    
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <UserProfile user={user} />
      <RightColumn>
        <h1>User Administration</h1>
        <Button onClick={() => setIsModalOpen(true)}>Create User</Button>
        <Button onClick={() => setIsManageUsersModalOpen(true)}>Manage Users</Button>
        <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={customStyles}>
          <CloseButton onClick={() => setIsModalOpen(false)}>X</CloseButton>
          <h2>Create User</h2>
          <form onSubmit={handleSubmit} className="user-form">
            <FormRow>
                <FormColumn>
                {imagePreview && <ImagePreview src={imagePreview} alt="User" />}
                <input type="file" onChange={handleImageUpload} />
                </FormColumn>
                <FormColumn>
                <select name="title" value={newUser.title} onChange={handleInputChange} required>
                    <option value="">Select a title</option>
                    {titles.map((title, index) => (
                    <option key={index} value={title}>
                        {title}
                    </option>
                    ))}
                </select>
                <input type="text" name="firstName" value={newUser.firstName} onChange={handleInputChange} placeholder="First name" required />
                <input type="text" name="lastName" value={newUser.lastName} onChange={handleInputChange} placeholder="Last name" required />
                <input type="email" name="email" value={newUser.email} onChange={handleInputChange} placeholder="Email" required />
                <input type="password" name="password" value={newUser.password} onChange={handleInputChange} placeholder="Password" required />
                <select name="userType" value={newUser.userType} onChange={handleInputChange} required>
                    <option value="">Select a user role</option>
                    {userRoles.map((role) => (
                    <option key={role.roleid} value={role.roleid}>
                        {role.roleName}
                    </option>
                    ))}
                </select>
                </FormColumn>
            </FormRow>
            <ButtonContainer>
                <Button type="submit">Create</Button>
            </ButtonContainer>
           </form>
        </Modal>
        <Modal isOpen={isManageUsersModalOpen} onRequestClose={() => setIsManageUsersModalOpen(false)} style={customStyles}>
        <CloseButton onClick={() => setIsManageUsersModalOpen(false)}>X</CloseButton>
          <ManageUsersPage titles={titles} roles={userRoles} />
        </Modal>
        {users.map((user) => (
          <div key={user.id}>
            <h2>{user.name}</h2>
            <p>{user.email}</p>
            {/* Add Edit and Delete buttons here */}
          </div>
        ))}
      </RightColumn>
    </div>
  );
};

export default UserAdminPage;