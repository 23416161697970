import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import './LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
      const userData = await dispatch(login(email, btoa(password)));
      if (userData) {
        //localStorage.setItem('token', JSON.parse(userData.jwtToken).token);
        //localStorage.setItem('user', JSON.stringify(userData));
        if (userData.role.toLowerCase() === 'manager') {
          navigate('/admin/users', { state: { user: userData } });
        } else {
          navigate('/user/profile', { state: { user: userData } });
        }
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  return (
    <div className="login-container">
      <img src="logo.png" alt="Logo" className="logo" />
      <form onSubmit={handleSubmit} className="login-form">
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
        {emailError && <div className="error">{emailError}</div>}
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
        <button type="submit">Login</button>
      </form>
      <Link to="/forgot-password" className="forgot-password-link">Forgot Password?</Link>
    </div>
  );
};

export default LoginPage;