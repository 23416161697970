import React from 'react';
import './AccountActivated.css';

const AccountActivated = ({ navigateTo }) => {
    const handleButtonClick = () => {
        navigateTo('/login');
    }

    return (
        <div className="account-activated-container">
            <h2>Your account has been activated!</h2>
            <button onClick={handleButtonClick}>Take me home</button>
        </div>
    );
}

export default AccountActivated;