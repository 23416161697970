import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import styled from 'styled-components';

const Button = styled.button`
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
`;

const UserRow = styled.tr`
  background-color: ${props => props.index % 2 === 0 ? '#f2f2f2' : '#ffffff'};
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%', // Adjust the width
    height: '60%', // Adjust the height
  },
};

const LargeInput = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
`;

const LargeSelect = styled.select`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
`;

const LargeButton = styled.button`
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px; /* Increase font size */
  margin: 4px 2px;
  cursor: pointer;
  width: 100%; /* Make the button full width */
`;

Modal.setAppElement('#root');

const ManageUsersPage = ({titles, roles}) => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://eckznaqxba.execute-api.eu-west-2.amazonaws.com/dev/api/backoffice/stafflist');
        setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setImagePreview(user.picture || 'default_user.png');
    setIsModalOpen(true);
  };

  const handleInputChange = (event) => {
    setSelectedUser({ ...selectedUser, [event.target.name]: event.target.value });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would make a PUT request to the API to update the user
    console.log(selectedUser);
  };

  return (
    <div>
      <h1>Manage Users</h1>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <UserRow key={user.email} index={index} onClick={() => handleEditClick(user)}>
              <td>{user.title}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td><Button>Edit</Button></td>
            </UserRow>
          ))}
        </tbody>
      </table>
      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={customStyles}>
      <CloseButton onClick={() => setIsModalOpen(false)}>X</CloseButton>
        <h2>Edit User</h2>
        {selectedUser && (
          <form onSubmit={handleSubmit} className="user-form">
          {imagePreview && <ImagePreview src={imagePreview} alt="User" />}
          <input type="file" onChange={handleImageUpload} />
          <LargeSelect name="title" value={selectedUser.title} onChange={handleInputChange} required>
            <option value="">Select a title</option>
            {titles.map((title, index) => (
              <option key={index} value={title}>
                {title}
              </option>
            ))}
          </LargeSelect>
          <LargeInput type="text" name="firstName" value={selectedUser.firstName} onChange={handleInputChange} required />
          <LargeInput type="text" name="lastName" value={selectedUser.lastName} onChange={handleInputChange} required />
          <LargeInput type="email" name="email" value={selectedUser.email} onChange={handleInputChange} required />
          <LargeSelect name="role" value={selectedUser.role} onChange={handleInputChange} required>
            <option value="">Select a role</option>
            {roles.map((role) => (
              <option key={role.roleid} value={role.roleid}>
                {role.roleName}
              </option>
            ))}
          </LargeSelect>
          <LargeButton type="submit">Update</LargeButton>
        </form>
        )}
      </Modal>
    </div>
  );
};

export default ManageUsersPage;